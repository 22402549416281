<template>
  <div class="row">
    <div class="col-md-12">
      <div class="header">
        {{ listHeading }}
      </div>
      <div class="download-list">
        <div v-for="(download, index) in customerDownloadList" :key="index">
          <div class="item">
            <div class="status-block">
              <div
                v-if="!hasDownloaded(download.id, download.isDownloaded)"
                class="status"
              >
                New
              </div>
            </div>
            <div class="detail-block">
              <div class="primary">{{ download.file_name }}</div>
              <div class="secondary">
                {{ download.available_at | moment("MMMM Do YYYY HH:mm") }}
              </div>
            </div>

            <div class="download-block">
              <font-awesome-icon
                icon="download"
                @click="triggerDownload(download)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { billDownload } from "@/components/mixins/bill-download";
library.add(fas);
export default {
  name: "DownloadList",
  props: {
    reference: {
      type: String,
      required: true
    },
    downloadList: {
      type: Array,
      required: true
    }
  },
  mixins: [billDownload],
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      customerDownloadList: [],
      downloadedFiles: []
    };
  },
  computed: {
    listHeading() {
      return this.customerDownloadList[0].attachment_type.replace(/_/g, " ");
    }
  },
  created() {
    this.setup();
  },
  methods: {
    setup() {
      this.customerDownloadList = this.downloadList;
    },
    hasDownloaded(id, isDownloaded) {
      return isDownloaded || this.downloadedFiles.includes(id);
    },
    triggerDownload(download) {
      const isAttachment = true;
      this.downloadBill(
        download.id,
        download.available_at,
        this.reference,
        false,
        false,
        isAttachment,
        download.file_name
      );
      this.downloadedFiles.push(download.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #777;
  margin-bottom: 0px;
}

.updated {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 20px;
  margin-top: 8px;

  span.highlight {
    font-weight: bold;
  }
}
.header {
  font-weight: bold;
  font-size: 0.8rem;
  text-align: left;
  margin-bottom: 3px;
}

.download-list {
  margin-bottom: 20px;
  .item {
    font-size: 0.9rem;
    text-align: left;
    color: #777;
    position: relative;
    background-color: white;
    border: 1px solid #dedede;
    padding: 8px;
    border-radius: 8px;
    margin: 5px 0px 10px;

    .status-block {
      position: absolute;
      left: 15px;
      top: 8px;

      .status {
        background-color: #99e6ff;
        border-radius: 5px;
        color: #111;
        font-weight: bold;
        font-size: 1rem;
        padding: 2px 8px;
        margin-top: 7px;
        text-align: center;
      }
    }

    .detail-block {
      padding-left: 75px;
      padding-right: 95px;

      .primary {
        color: #222;
        font-weight: bold;
        font-size: 1rem;
      }

      .secondary {
        margin-top: 0px;
        font-weight: bold;
        color: #999;
        font-size: 0.8rem;
        min-height: 19px;

        &.is-link {
          cursor: pointer;
          color: $dePurple;
        }
      }
    }

    .download-block {
      position: absolute;
      right: 20px;
      top: 14px;
      font-weight: bold;
      font-size: 1.2rem;
      color: #222;
      cursor: pointer;
    }
  }
}
</style>
